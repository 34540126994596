import { useState } from "react";
import Textarea from "../Textarea/Textarea";
import Sucess from "./Sucess";
import Acompanhing from '../Page/Acompanhing';


function Content({ acompanhing }) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [protocolo, setProtocolo] = useState('');

    const handleDenunciaSubmit = (item) => {
        let data = JSON.parse(item.data[0].data);
        console.log(data[0].protocolo);

        setProtocolo(data[0].protocolo);
        // Lógica para enviar a denúncia aqui
        // Após o envio bem-sucedido, defina isSubmitted como true
        setIsSubmitted(true);
    };

    return (
        <>
            {!isSubmitted ? (
                <div>
                    <div className="w-3/5 max-sm:w-4/5 mx-auto flex flex-col gap-5 font-inter pt-16">
                        <h1 className="text-black font-extrabold text-4xl max-sm:text-2xl">Canal de Denúncias e Sugestões Anônimas</h1>
                        <p className="text-base">
                            Este canal é 100% anônimo e seguro para clientes e colaboradores:
                        </p>
                        <ul className="list-disc pl-5 text-base">
                            <li>Denuncie condutas antiéticas, assédio, fraudes ou irregularidades.</li>
                            <li>Relate problemas graves ou sugira melhorias sem se identificar.</li>
                        </ul>
                        <h2 className="text-lg font-bold mt-4">Como Funciona:</h2>
                        <ul className="list-decimal pl-5 text-base">
                            <li>Descreva seu relato no campo abaixo (não inclua dados pessoais).</li>
                            <li>
                                Após o envio, guarde o número de protocolo para consultar a resposta, aqui mesmo nesta
                                própria página, no botão “Acompanhar PROTOCOLO”.
                            </li>
                            <li>
                                <span className="font-semibold">Importante:</span> Se perder o protocolo, não será possível
                                recuperar a mensagem.
                            </li>
                        </ul>
                        <h2 className="text-lg font-bold mt-4">⏳ Prazo para Resposta:</h2>
                        <p className="text-base">O prazo para análise e retorno é de até 7 dias úteis.</p>
                        <h2 className="text-lg font-bold mt-4">⚠️ Atenção:</h2>
                        <p className="text-base">
                            Este canal não é para atendimento sobre pedidos. Para suporte pós-venda, entre em contato com o
                            seu vendedor ou pelo WhatsApp (19) 99720-9278, opção 2 - Pós-vendas.
                        </p>
                    </div>
                    <div className="w-3/5 max-sm:w-4/5 mx-auto pt-12">
                        <p className="text-base"><b>Preencha o campo abaixo:</b></p>
                        <Textarea onSubmit={handleDenunciaSubmit} />
                    </div>
                </div>

            ) : (
                <Sucess
                    protocolo={protocolo} />
            )}




        </>
    );
}

export default Content;