import ReCAPTCHA from "react-google-recaptcha";
import Button from "../Button/Button";
import { useState } from "react";
import axios from "axios";
import Sucess from "./Sucess";

function Formulario() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showButton, setShowButton] = useState(true); // Controla a visibilidade do botão
    const [protocolo, setProtocolo] = useState('');
    const [text, setText] = useState('ENVIAR DENÚNCIA');
    const [repactha, setRepactha] = useState(null);
    const [loading, setLoading] = useState('');
    const [textareaValue, setTextareaValue] = useState('');
    const [isTextareaEmpty, setIsTextareaEmpty] = useState(true);
    const [borderColor, setBorderColor] = useState('border-gray-300');
    const [result, setResult] = useState('');


    const [formData, setFormData] = useState({
        nome: "",
        cnpjCpf: "",
        email: "",
        telefone: "",
        nf: "",
        responsavel: "",
    });

    const [errors, setErrors] = useState({
        nome: false,
        cnpjCpf: false,
        email: false,
        telefone: false,
        responsavel: false,
    });

    const apiUrl = process.env.REACT_APP_VRCL === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API;


    const handleEnviar = (e) => {
        if (isSubmitted) return; // Impedir múltiplos envios

        e.preventDefault();
        let formIsValid = true;
        let updatedErrors = {};

        // Verificando campos obrigatórios
        for (const field in formData) {
            if (field === "nf") continue; // Ignora a validação para o campo "nf"

            updatedErrors[field] = !formData[field]; // Se estiver vazio, será true (erro)
            if (!formData[field]) formIsValid = false;
        }

        // Verificando se os campos com números estão preenchidos corretamente
        if (!/^\d+$/.test(formData.telefone) || !formData.telefone) {
            updatedErrors.telefone = true;
            formIsValid = false;
        }
        if (!/^\d+$/.test(formData.cnpjCpf) || !formData.cnpjCpf) {
            updatedErrors.cnpjCpf = true;
            formIsValid = false;
        }
        if (textareaValue === '') {
            setBorderColor('border-red-600');
        } else {
            setBorderColor('border-gray-300');
            // console.log(result);
        }

        setErrors(updatedErrors);

        if (!repactha) {
            // alert("Por favor, complete o reCAPTCHA antes de enviar.");
            return;
        }

        if (formIsValid) {
            const finalFormData = { ...formData, textarea: textareaValue }; // Adicionando o textarea ao formData
            setLoading('fa fa-circle-o-notch fa-spin')
            setIsSubmitted(true)
            setText('ENVIANDO')

            axios.post(`${apiUrl}/denuncia/form/`, finalFormData, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "ooooooo",
                },
            })
                .then((response) => {
                    let data = JSON.parse(response.data[0].data);
                    setProtocolo(data[0].protocolo);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error.message);
                    setLoading(false);
                });

        } else {
            console.log("Erro ao enviar o formulário", updatedErrors);
        }
    };



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleTextareaChange = (event) => {
        const newValue = event.target.value;

        if (newValue.length <= 1000) {
            setTextareaValue(newValue);
            if (newValue.trim() === '') {
                setIsTextareaEmpty(true);
                setBorderColor('border-red-600');
            } else {
                setIsTextareaEmpty(false);
                setResult(newValue)
                setBorderColor('border-gray-300');

            }
        }
    }

    function onChange(value) {
        // console.log("Captcha value:", value);
        setRepactha(value)
    };


    return (
        <>
            {!isSubmitted ? (
                <>
                    <div className="w-3/5 max-sm:w-4/5 mx-auto flex flex-col gap-5 font-inter pt-16">
                        <h1 className="text-black font-extrabold text-4xl max-sm:text-2xl">Canal de Ouvidoria</h1>
                        <p className="text-base">
                            Este canal é onde você poderá relatar um problema que não foi solucionado adequadamente pelo seu vendedor ou pela equipe de Pós-vendas.
                        </p>
                        <p className="text-base">
                            <b>Como Funciona:</b>
                        </p>
                        <ul className="list-disc pl-5 text-base">
                            <li>Identifique-se, pois precisamos encontrar o seu cadastro.</li>
                            <li>Informe quem cuidou do seu problema.</li>
                            <li>Descreva seu relato no campo abaixo com o máximo possível de informações para que possamos analisá-lo adequadamente.</li>
                            <li>Será gerado um protocolo e enviado para o e-mail cadastrado.</li>
                        </ul>
                        <h2 className="text-lg font-bold mt-4">⏳ Prazo para Resposta:</h2>
                        <p className="text-base">O prazo para análise e retorno é de até 7 dias úteis.</p>
                        <p className="text-base">
                            <b>Preencha todos os campos abaixo para que possamos identificar o seu cadastro:</b>
                        </p>
                    </div>
                    <div className="w-3/5 max-sm:w-4/5 mx-auto flex flex-col gap-2 p-4 rounded-lg">
                        <label className="font-medium text-gray-700 text-sm">Nome completo</label>
                        <input
                            type="text"
                            name="nome"
                            value={formData.nome}
                            onChange={handleChange}
                            className={`text-sm py-1 px-2 border-b ${errors.nome ? 'border-red-500' : 'border-gray-400'} focus:outline-none focus:border-blue-500`}
                        />
                        {errors.nome && <p className="text-red-500 text-xs">Este campo é obrigatório.</p>}

                        <label className="font-medium text-gray-700 text-sm">CNPJ ou CPF cadastrado:</label>
                        <input
                            type="number"
                            onKeyDown={(e) => {
                                if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                }
                            }}
                            onPaste={(e) => {
                                const pastedText = e.clipboardData.getData("text");
                                if (pastedText.toLowerCase().includes("e")) {
                                    e.preventDefault();
                                }
                            }}
                            name="cnpjCpf"
                            value={formData.cnpjCpf}
                            onChange={handleChange}
                            className={`appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none text-sm py-1 px-2 border-b ${errors.cnpjCpf ? 'border-red-500' : 'border-gray-400'} focus:outline-none focus:border-blue-500`}
                        />
                        {errors.cnpjCpf && <p className="text-red-500 text-xs">Este campo deve conter apenas números.</p>}

                        <label className="font-medium text-gray-700 text-sm">E-mail:</label>
                        <input
                            type="text"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`text-sm py-1 px-2 border-b ${errors.email ? 'border-red-500' : 'border-gray-400'} focus:outline-none focus:border-blue-500`}
                        />
                        {errors.email && <p className="text-red-500 text-xs">Este campo é obrigatório.</p>}

                        <label className="font-medium text-gray-700 text-sm">Telefone/WhatsApp:</label>
                        <input
                            type="number"
                            onKeyDown={(e) => {
                                if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                }
                            }}
                            onPaste={(e) => {
                                const pastedText = e.clipboardData.getData("text");
                                if (pastedText.toLowerCase().includes("e")) {
                                    e.preventDefault();
                                }
                            }}
                            name="telefone"
                            value={formData.telefone}
                            onChange={handleChange}
                            className={`appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none text-sm py-1 px-2 border-b ${errors.telefone ? 'border-red-500' : 'border-gray-400'} focus:outline-none focus:border-blue-500`}
                        />
                        {errors.telefone && <p className="text-red-500 text-xs">Este campo deve conter apenas números.</p>}

                        <label className="font-medium text-gray-700 text-sm">NF:</label>
                        <input
                            type="number"
                            onKeyDown={(e) => {
                                if (e.key === "e" || e.key === "E") {
                                    e.preventDefault();
                                }
                            }}
                            onPaste={(e) => {
                                const pastedText = e.clipboardData.getData("text");
                                if (pastedText.toLowerCase().includes("e")) {
                                    e.preventDefault();
                                }
                            }}
                            name="nf"
                            value={formData.nf}
                            onChange={handleChange}
                            className={`appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none text-sm py-1 px-2 border-b ${errors.nf ? 'border-red-500' : 'border-gray-400'} focus:outline-none focus:border-blue-500`}
                        />
                        <label className="font-medium text-gray-700 text-sm">Quem cuidou do seu problema até agora:</label>
                        <input
                            type="text"
                            name="responsavel"
                            value={formData.responsavel}
                            onChange={handleChange}
                            className={`text-sm py-1 px-2 border-b ${errors.responsavel ? 'border-red-500' : 'border-gray-400'} focus:outline-none focus:border-blue-500`}
                        />
                        {errors.responsavel && <p className="text-red-500 text-xs">Este campo é obrigatório.</p>}

                    </div>

                    {showButton && (
                        <div className="w-3/5 max-sm:w-4/5 mx-auto pt-12">
                            <p className="text-base"><b>Preencha o campo abaixo:</b></p>
                            <textarea
                                className={`p-2 border w-full h-32 rounded-md resize-none outline-none ${borderColor}`}
                                placeholder="Nos diga qual sua denúncia."
                                value={textareaValue}
                                onChange={handleTextareaChange}
                            />
                            {borderColor === 'border-red-600' && (
                                <p className="text-red-600">Este campo não pode estar vazio.</p>
                            )}

                            <div className="text-right text-gray-500">
                                {textareaValue.length}/1000
                            </div>
                        </div>
                    )}
                    <div className="w-3/5 max-sm:w-4/5 mx-auto">
                        <ReCAPTCHA
                            sitekey="6LdMn_4oAAAAAL1Fd27eAjgTrngKiRUkh8jgxZ0j"
                            onChange={onChange}
                        />,
                        <Button
                            text={text}
                            loading={loading}
                            onClick={handleEnviar}
                            color='bg-red-600'
                        />
                    </div>

                </>
            ) :

                (
                    <Sucess
                        protocolo={protocolo} />
                )}

        </>
    );
}

export default Formulario;
