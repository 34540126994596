import { Route, Routes } from "react-router-dom";
import { useState } from "react";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Content from "./components/Page/Content";
import Home from "./components/Page/Home";
import Formulario from "./components/Page/Form";
import Acompanhing from "./components/Page/Acompanhing";

function App() {
  const [accompanhing, setAccompanhing] = useState(null);

  const handleAccompanhingChange = (value) => {
    setAccompanhing(value);
    console.log("Novo valor de accompanhing:", value);
  };

  return (
    <>
      <Header onAccompanhingChange={handleAccompanhingChange} />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ouvidoria" element={<Content acompanhing={accompanhing} />} />
        <Route path="/acompanhamento" element={<Acompanhing acompanhing={accompanhing} />} />
        <Route path="/form" element={<Formulario />} />
      </Routes>

      <Footer />
    </>
  );
}

export default App;
