import { useState, useEffect } from "react";
import Button from "../Button/Button";
import alert from '../../img/alert.png';
import { useNavigate } from "react-router-dom";
import axios from "axios";

function Acompanhing({ acompanhing }) {

    const [data, setData] = useState(acompanhing); // Estado para armazenar os dados
    const [borderColor, setBorderColor] = useState('border-gray-300');
    const [textareaValue, setTextareaValue] = useState('');
    const [isTextareaEmpty, setIsTextareaEmpty] = useState(true);
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [text, setText] = useState('Responder');
    const [protocolo, setProtocolo] = useState('');

    useEffect(() => {
        if (protocolo) {
            handleEnviar();
        }
    }, [protocolo]);

    const navigate = useNavigate();


    const handlevoltar = () => {
        navigate('/')
    }

    const handleTextareaChange = (event) => {
        const newValue = event.target.value;

        if (newValue.length <= 1000) {
            setTextareaValue(newValue);
            if (newValue.trim() === '') {
                setIsTextareaEmpty(true);
                setBorderColor('border-red-600');
            } else {
                setIsTextareaEmpty(false);
                setResult(newValue)
                setBorderColor('border-gray-300');

            }
        }
    }

    const apiUrl = process.env.REACT_APP_VRCL === 'production' ? process.env.REACT_APP_API : process.env.REACT_APP_API;

    const handleEnviar = () => {
        if (isSubmitted) return; // Impedir múltiplos envios

        let formIsValid = true;
        let updatedErrors = {};
        // Verificando se os campos com números estão preenchidos corretamente

        if (textareaValue === '') {
            setBorderColor('border-red-600');
            return
        } else {
            setBorderColor('border-gray-300');
            // console.log(result);
        }

        if (formIsValid) {

            setLoading('fa fa-circle-o-notch fa-spin')
            // setIsSubmitted(true)
            setText('ENVIANDO')
            const dataToSend = {
                protocolo: protocolo,
                resposta: textareaValue
            }

            console.log(dataToSend)

            axios.post(`${apiUrl}/denuncia/acompanhamento/resposta/`, dataToSend, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "ooooooo",
                },
            })
                .then((response) => {
                    setLoading(false);

                    axios.post(`${apiUrl}/denuncia/acompanhamento/`, dataToSend, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "ooooooo",
                        },
                    })
                        .then((response) => {
                            console.log('aqui')
                            setData(response.data); // Atualiza o estado com os novos dados
                            setTextareaValue(''); // Limpa o textarea
                            setIsSubmitted(false)
                            setLoading(false);
                            setProtocolo('')
                            setText('Responder');
                        })
                        .catch((error) => {
                            console.log(error.message);
                            setLoading(true);
                        });
                })
                .catch((error) => {
                    console.log(error.message);
                    setLoading(false);
                });

        } else {
            console.log("Erro ao enviar o formulário", updatedErrors);
        }
    };
    return (
        <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '26px' }}><strong>Canal de Ouvidoria, denúncias e sugestões anônimas</strong></h1>
            <h1 style={{ textAlign: 'center', marginBottom: '20px', fontSize: '26px' }}><strong>Acompanhamento</strong></h1>
            {data?.map((item, index) => (
                <div
                    key={index}
                    style={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        padding: '15px',
                        marginBottom: '15px',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                    }}
                >
                    {item.status === 1 && (
                        <div className="bg-yellow-300 text-black p-2 rounded mb-4 text-center font-bold flex items-center justify-center gap-2">
                            <img src={alert} alt="Alerta" className="h-5 w-auto" />
                            Seu relato está em análise. Por favor, aguarde.
                            <img src={alert} alt="Alerta" className="h-5 w-auto" />
                        </div>
                    )}
                    <div style={{ marginBottom: '10px' }}>
                        <strong>Protocolo:</strong> {item.protocolo}
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <strong>Status:</strong>{' '}
                        {item.status === 0
                            ? 'Pendente'
                            : item.status === 1
                                ? `Em Analise. Última atividade em ${new Date(item.dt_status).toLocaleString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                })}`
                                : item.status === 2
                                    ? 'Concluído'
                                    : item.status === 3
                                        ? 'Aguardando Resposta'
                                        : item.status === 4
                                            ? 'Aguardando Resposta da Ouvidoria'
                                            : ''}

                        {item.status === 1 && (
                            <div style={{ fontStyle: 'italic', fontSize: 'small', color: 'gray', marginTop: '5px' }}>
                                O seu relato está em investigação. Por favor, consulte novamente mais tarde. O prazo de análise e retorno é
                                de até 7 dias úteis. Obrigado.
                            </div>
                        )}
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <strong>Relato:</strong>
                        <p style={{ margin: '5px 0', whiteSpace: 'pre-wrap' }}>{item.mensagem || 'N/A'}</p>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <strong>Data do relato:</strong> {new Date(item.dt_mensagem).toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })}
                    </div>
                    {item.resposta && item.status == 2 ? (
                        <div style={{ marginBottom: '10px' }}>
                            <strong>Resposta:</strong>
                            <p style={{ margin: '5px 0' }}>{item.resposta}</p>
                        </div>) : ('')}

                    {item.status == 2 ? (item.dt_resposta ? (<div style={{ marginBottom: '10px' }}>
                        <strong>Concluído em:</strong>{' '}
                        {new Date(item.dt_resposta).toLocaleString('pt-BR', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })}
                    </div>) : ('')) : ('')
                    }
                    {item.canal == 'IDENTIFICADO' ? (<div style={{ marginBottom: '10px', backgroundColor: '#e3e3e3', padding: '7px', borderRadius: '5px' }}>
                        <strong>Historico de mensagens:</strong>
                        <p style={{ margin: '5px 0', whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: item.historico }}></p>
                    </div>) : ('')}
                    {item.status == 3 || item.status == 4 ? (
                        <>
                            <div className=" mx-auto pt-12">
                                <p className="text-base"><b>Caso ainda necessário, continue interagindo com a Ouvidoria:</b></p>
                                <textarea
                                    className={`p-2 border w-full h-32 rounded-md resize-none outline-none ${borderColor}`}
                                    value={textareaValue}
                                    onChange={handleTextareaChange}
                                />
                                {borderColor === 'border-red-600' && (
                                    <p className="text-red-600">Este campo não pode estar vazio.</p>
                                )}

                                <div className="text-right text-gray-500">
                                    {textareaValue.length}/1000
                                </div>
                            </div>
                            <div className="flex justify-end mx-auto">
                                <Button
                                    text={text}
                                    loading={loading}
                                    onClick={() => setProtocolo(item.protocolo)}
                                    color='bg-red-600'
                                />
                            </div>
                        </>
                    ) : ('')}
                </div>

            ))}

            <div className="flex justify-center">
                <Button
                    onClick={handlevoltar}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                    text='Voltar'
                    // loading={loading}
                    color='bg-blue-500'
                />
            </div>

        </div>
    );
}

export default Acompanhing;
