import { useNavigate } from "react-router-dom";

const MeuComponente = () => {
    const navigate = useNavigate();

    return (
        <div className="w-3/5 max-sm:w-4/5 mx-auto flex flex-col gap-5 font-inter pt-16">
            <h1 className="text-black font-extrabold text-4xl max-sm:text-2xl">
                Canal de Ouvidoria, denúncias e sugestões anônimas
            </h1>

            {/* Primeiro quadro - Redireciona para /ouvidoria */}
            <div
                className="bg-white border-2 border-gray-300 rounded-md p-5 transition-all hover:border-blue-500 hover:bg-blue-50 cursor-pointer shadow-md"
                onClick={() => navigate("/ouvidoria")}
            >
                <p className="text-base text-gray-700">
                    Clique aqui se você é um cliente ou colaborador e quer, de forma <b>100% anônima e segura</b>, denunciar uma conduta antiética, assédio, fraude ou irregularidade, ou mesmo relatar um problema grave ou sugerir melhorias <b>SEM SE IDENTIFICAR.</b>
                </p>
            </div>

            <p className="text-lg text-center font-semibold text-gray-600">ou</p>

            {/* Segundo quadro - Redireciona para /form */}
            <div
                className="bg-white border-2 border-gray-300 rounded-md p-5 transition-all hover:border-blue-500 hover:bg-blue-50 cursor-pointer shadow-md"
                onClick={() => navigate("/form")}
            >
                <p className="text-base text-gray-700">
                    Clique aqui se você é um cliente que <b>QUER SE IDENTIFICAR</b> e relatar um problema grave que não foi solucionado adequadamente pelo seu vendedor ou pelo suporte pós-vendas no WhatsApp (19) 99720-9278, opção 2 - Pós-vendas.
                </p>
            </div>
        </div>
    );
};

export default MeuComponente;
